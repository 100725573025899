import Firebase from "@services/firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebaseConfig from "@helpers/config";
import emitter from "@utils/emitter";

export const requestNotificationPermission = async () => {
    const firebaseApp = Firebase.app();
    const messaging = getMessaging(firebaseApp);

    try {
        const token = await getToken(messaging, {
            vapidKey: firebaseConfig.FIREBASE_VAPID_KEY,
        });
        console.info("FCM Token:", token);
        if (token) {
            return token;
        }
        return null;
    } catch (error) {
        console.log("Error getting token:", error);
        return "";
    }
};

export const onNotificationListener = () =>
    new Promise((resolve) => {
        const firebaseApp = Firebase.app();
        const messaging = getMessaging(firebaseApp);
        onMessage(messaging, async (payload) => {
            try {
                const registration = await navigator?.serviceWorker?.ready;

                await registration?.showNotification?.(payload?.data?.title!, {
                    body: payload.data?.body,
                    icon: payload.data?.image,
                    requireInteraction: true,
                    data: {
                        ...payload.data,
                    },
                });

                resolve(payload);
            } catch (error) {
                console.error("Error showing notification:", error);
            }
        });
    });
