export const delay = (delayInms: number) => {
    return new Promise((resolve) => {
        const ref = setTimeout(() => {
            resolve(null);
            clearTimeout(ref);
        }, delayInms);
    });
};

export const rmReverseDuplicates = (arr: any[]) => {
    const keys: string[] = [];
    arr.forEach((a) => {
        if (typeof a != "object") return;
        Object.keys(a).map((key) => {
            if (typeof a[key] != "string") return;
            if (keys.includes(key)) return;
            keys.push(key);
        });
    });
    const uniqueMap = new Map();
    arr.forEach((item) => {
        const compositeKey = keys.map((key) => item[key]).join("|");
        uniqueMap.set(compositeKey, item);
    });
    return Array.from(uniqueMap.values());
};
export function getChromeVersion(): any {
    var userAgent = navigator.userAgent;
    // Check if it's Chrome by ensuring "Chrome" is in the userAgent
    if (userAgent.includes("Chrome")) {
        // Extract the version after 'Chrome/'
        var versionMatch = userAgent.match(/Chrome\/([\d.]+)/);
        if (versionMatch) {
            try {
                return parseInt(versionMatch[1]); // Returns the version number as a string
            } catch {
                return versionMatch[1]; // Returns the version number as a string
            }
        }
    }
    // Return null if not Chrome or version can't be found
    return 0;
}
export function getSafariVersion(): any {
    if (typeof window == "undefined") return 0;
    var userAgent = navigator.userAgent;
    // Check if it's Safari by ensuring "Safari" is in the userAgent but not "Chrome"
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        // Extract the version after 'Version/'
        var versionMatch = userAgent.match(/Version\/([\d.]+)/);
        if (versionMatch) {
            try {
                return Number(versionMatch[1]); // Returns the version number as a string
            } catch {
                return versionMatch[1]; // Returns the version number as a string
            }
        }
    }
    // Return null if not Safari or version can't be found
    return 0;
}
